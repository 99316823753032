import React from "react";
import { Container } from "react-bootstrap";

export default function NotFound() {
	return (
		<Container>
			<h1>Error 404</h1>
			<p>It looks like this page could not be found.</p>
		</Container>
	);
}
