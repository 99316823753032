import axios from "axios";

export interface IKahoot {
  card: {
    title: string;
    uuid: string;
  };
}

export interface IKahoot {
  card: {
    title: string;
    uuid: string;
  };
  kahoot: {
    uuid: string;
    questions: IQuestion[];
  };
}

export interface IQuestion {
  type: string;
  question: string;
  choices: IChoice[];
}

export interface IChoice {
  answer: string;
  correct: boolean;
}

export async function fetchFullKahoot(id?: string): Promise<IKahoot> {
  const { data }: any = await axios.get("https://proxy.larspeder.workers.dev", {
    headers: {
      "X-Target": `https://create.kahoot.it/rest/kahoots/${id}/card/?includeKahoot=true`
    }
  });
  return data;
}

export async function fetchUserKahoots(
  username?: string
): Promise<Array<IKahoot>> {
  const kahoots: Array<IKahoot> = [];
  let lastResult: any = undefined;
  while (!lastResult || kahoots.length < lastResult.totalHits) {
    const { data } = await axios.get("https://proxy.larspeder.workers.dev", {
      headers: {
        "X-Target": `https://create.kahoot.it/rest/kahoots/public/user/${username}?limit=100&cursor=${kahoots.length}`
      }
    });
    lastResult = data;

    for (const kahoot of data.entities) {
      kahoots.push(kahoot);
    }
  }
  return kahoots;
}
