import React from "react";
import { IKahoot, fetchFullKahoot } from "../api";
import { Container } from "react-bootstrap";

interface IProps {
	match: {
		params: {
			kahoot: string;
		};
	};
}

interface IState {
	kahoot?: IKahoot;
	error?: number;
}

export default class ViewKahoot extends React.Component {
	private id: string;
	public state: IState = {};

	constructor(public props: IProps) {
		super(props);
		this.id = this.props.match.params.kahoot;
		fetchFullKahoot(this.id)
			.then((kahoot: IKahoot) => this.setState({ kahoot }))
			.catch((error) => this.setState({ error: error?.response?.status || 1 }));
	}

	render() {
		return (
			<Container>
				{!this.state.error ? (
					this.state.kahoot ? (
						<>
							<h1>{this.state.kahoot.card.title}</h1>
							<div className="questions">
								{this.state.kahoot.kahoot.questions.map((question, index) => (
									<div className="question" key={index}>
										<h2>{question.question}</h2>
										<div className="answers">
											{question.choices.map((choice, index) => (
												<div className={`answer${choice.correct ? " correct" : ""}`} key={index}>
													<span>{choice.answer}</span>
													{!choice.correct || <img src="/assets/img/tick.svg" alt="correct" />}
												</div>
											))}
										</div>
									</div>
								))}
							</div>
						</>
					) : (
						<h1>Loading...</h1>
					)
				) : (
					<>
						<h1>Error {this.state.error}</h1>
						{this.state.error === 404 ? (
							<p>It looks like this kahoot could not be found.</p>
						) : this.state.error === 403 ? (
							<p>It looks like this kahoot is private.</p>
						) : (
							<p>Unknown error.</p>
						)}
					</>
				)}
			</Container>
		);
	}
}
