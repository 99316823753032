import React from "react";
import { IKahoot, fetchUserKahoots } from "../api";
import { Container, ListGroup, FormControl, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

interface IProps {
	match: {
		params: {
			creator: string;
		};
	};
}

interface IState {
	kahoots?: Array<IKahoot>;
	filter: string;
	error?: number;
}

export default class ViewCreator extends React.Component {
	private username: string;
	public state: IState = {
		filter: ""
	};

	constructor(public props: IProps) {
		super(props);
		this.username = this.props.match.params.creator;
		fetchUserKahoots(this.username)
			.then((kahoots: Array<IKahoot>) => this.setState({ kahoots }))
			.catch((error) => this.setState({ error: error?.response?.status || 1 }));
	}

	render() {
		return (
			<Container>
				{!this.state.error ? (
					this.state.kahoots ? (
						<>
							<h1>{this.username}</h1>
							<FormControl
								placeholder="Filter"
								className="mb-3"
								value={this.state.filter}
								onChange={(event: any) => this.setState({ filter: event.target.value })}
							/>
							<ListGroup>
								{this.state.kahoots
									.filter((kahoot) =>
										(kahoot.card.title + kahoot.card.uuid).toLowerCase().includes(this.state.filter.toLowerCase())
									)
									.map((kahoot) => (
										<ListGroup.Item key={kahoot.card.uuid}>
											<Row>
												<Col>
													<span className="display-block">{kahoot.card.title}</span>
													<small className="text-muted">{kahoot.card.uuid}</small>
												</Col>
												<Col xs="auto">
													<Link to={`/kahoot/${kahoot.card.uuid}`}>
														<Button variant="outline-success">View</Button>
													</Link>
												</Col>
											</Row>
										</ListGroup.Item>
									))}
							</ListGroup>
						</>
					) : (
						<>
							<h1>{this.username}</h1>
							<p>Loading...</p>
						</>
					)
				) : (
					<>
						<h1>Error {this.state.error}</h1>
						{this.state.error === 404 ? <p>It looks like this user could not be found.</p> : <p>Unknown error.</p>}
					</>
				)}
			</Container>
		);
	}
}
