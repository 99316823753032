import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style/index.scss";

import ViewKahoot from "./components/ViewKahoot";
import ViewCreator from "./components/ViewCreator";
import FindKahoot from "./components/FindKahoot";
import NotFound from "./components/NotFound";
import { IKahoot } from "./api";

interface IState {
	username?: string;
	filter?: string;
	kahoots?: IKahoot[];
}

export default class App extends React.Component {
	public state: IState = {};

	render() {
		return (
			<>
				<Router>
					<Switch>
						<Route exact path="/" component={FindKahoot} />
						<Route exact path={["/", "/kahoot/:kahoot([a-z0-9-]{36})"]} component={ViewKahoot} />
						<Route exact path={["/", "/creator/:creator([^/]{1,32})"]} component={ViewCreator} />
						<Route component={NotFound} />
					</Switch>
				</Router>
			</>
		);
	}
}
