import React from "react";
import { Container, InputGroup, FormControl, Button } from "react-bootstrap";

interface IKahoot {
	card: {
		title: string;
		uuid: string;
	};
}

interface IState {
	username: string;
}

interface IProps extends React.Props<FindKahoot> {
	history: any;
}

export default class FindKahoot extends React.Component<IProps> {
	public state: IState = {
		username: ""
	};

	render() {
		return (
			<>
				<Container>
					<h1>Kahoot Finder</h1>
					<InputGroup className="mb-3">
						<FormControl
							placeholder="Username"
							value={this.state.username}
							onChange={(event: any) => this.setState({ username: event.target.value })}
						/>
						<InputGroup.Append>
							<Button
								variant="outline-secondary"
								onClick={() => this.props.history.push(`/kahoot/${this.state.username}`)}
							>
								Kahoot
							</Button>
						</InputGroup.Append>
						<InputGroup.Append>
							<Button
								variant="outline-secondary"
								onClick={() => this.props.history.push(`/creator/${this.state.username}`)}
							>
								User
							</Button>
						</InputGroup.Append>
					</InputGroup>
				</Container>
			</>
		);
	}
}
